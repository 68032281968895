/* eslint-disable */
import { createSlice } from "@reduxjs/toolkit";

const topicStore = createSlice({
  name: "topicStore",
  initialState: {
    topicOptionValue: "",
    solutionOptionValue: "",
  },
  reducers: {
    setTopicValue: (state, action) => {
      const currState = state;
      currState.topicOptionValue = action.payload;
    },
    setTopicAndSolution: (state, action) => {
      const currState = state;
      currState.topicOptionValue = "Consultation";
      currState.solutionOptionValue = action.payload.solutionOptionValue;
    },
    resetState: (state) => {
      state.topicOptionValue = "";
      state.solutionOptionValue = "";
    },
  },
});

export const topicStoreActions = topicStore.actions;
export default topicStore;

import { createSlice } from "@reduxjs/toolkit";

import languages from "../../utils/languages.json";

const currLang = localStorage.getItem("language");
const initialLang = currLang ?
  languages?.languages?.filter(
      (l) => l.name.toLowerCase() === currLang.toLowerCase()
    )[0]?.id :
  1;

const contentSlice = createSlice({
  name: "content",
  initialState: {
    languages: languages.languages,
    activeLanguage: initialLang,
  },
  reducers: {
    setActiveLanguage(state, action) {
      const currState = state;
      currState.activeLanguage = action.payload.id;
      sessionStorage.setItem("alreadyShow", "not showing");
      localStorage.setItem("language", action.payload.name);
      window.location.reload();
    },
  },
});

export const contentActions = contentSlice.actions;
export default contentSlice;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reload: true,
};

const reloadStore = createSlice({
  name: "reloadStore",
  initialState,
  reducers: {
    updateReload: (state, action) => {
      state.reload = action.payload;
    },
  },
});

export const { updateReload } = reloadStore.actions;
export default reloadStore.reducer;
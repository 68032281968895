/* eslint-disable */

import React, { Component } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import PAGE_404 from "../pages/page_404/page_404";
import SILAT_HEADER_1016 from '../sections/Silat_Header_1016/Silat_Header';
import SILAT_FOOTER_1017 from '../sections/Silat_Footer_1017/Silat_Footer';

export default function Site() {
    let location = useLocation();
    let path_name = location.pathname;
    let path_name_without_last_slash = path_name.replace(/\/$/, '');
    if (path_name === '/') path_name_without_last_slash = '/';
    const urls_use_global_sections = [
        "/",
    ];
    const urls_use_global_sections_and_has_params = [
    ];
    const is_use_global_sections = urls_use_global_sections.includes(path_name_without_last_slash);
    const use_global_sections_and_has_params = urls_use_global_sections_and_has_params.some(url => path_name_without_last_slash.startsWith(url));
    let header = null
    if (is_use_global_sections || use_global_sections_and_has_params) header = <SILAT_HEADER_1016/>
    let footer = null
    if (is_use_global_sections || use_global_sections_and_has_params) footer = <SILAT_FOOTER_1017/>
    return (
        <>
            {header}
            <Routes>
                <Route path={`/`} exact element={<PAGE_404/>}/>
            </Routes>
            {footer}
         </>
         );
}

import languages from "./languages.json";

export default () => {
  const lg = localStorage.getItem("language") || "English";

  const currentLang = languages?.languages?.filter(
    (l) => l.name.toLowerCase() === lg.toLowerCase()
  );

  return currentLang.length ? currentLang[0] : { id: 1 };
};

/* eslint-disable */

import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import Site from './routes/site';
import ScrollToTop from './context/components/ScrollToTop/ScrollToTop';

function App() {
    return (
        <div className="wrapper">
            <BrowserRouter>
                <ScrollToTop>
                    <Site />
                </ScrollToTop>
            </BrowserRouter>
        </div>
    );
}

export default App;

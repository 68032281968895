/* eslint-disable */

import { configureStore } from "@reduxjs/toolkit";
import contentSlice from "./slices/contentSlice";
import contactSlice from "./slices/contactSlice";
import TopicStore from "./topicStore";
import reloadStore from "./reloadStore/reloadStore";

const store = configureStore({
  reducer: {
    content: contentSlice.reducer,
    contact: contactSlice.reducer,
    TopicStore: TopicStore.reducer,
    reloadStore,
  },
});

export default store;

import { createSlice } from "@reduxjs/toolkit";

const contactSlice = createSlice({
  name: "contact",
  initialState: {
    isOpen: false,
  },
  reducers: {
    handleContact(state, action) {
      const currState = state;
      currState.isOpen = !currState.isOpen;
    },
  },
});
export const { handleContact } = contactSlice.actions;
export default contactSlice;
